<template>
  <v-card-text class="text-subtitle-1 pt-0 pb-10">
    <span v-if="props.isTitle">Work items</span>
    <div class="work-items-container">
      <div class="v-table v-table--has-top v-table--has-bottom v-table--density-compact v-data-table">
        <div class="v-table__wrapper">
          <table>
            <thead>
              <tr>
                <th v-if="tableHeaders.includes('Name')" class="v-data-table__td v-data-table-column--align-start v-data-table__th">
                  <div class="v-data-table-header__content">Name</div>
                </th>
                <th v-if="tableHeaders.includes('Date')" class="header">Date</th>
                <th v-if="!props.readonly" class="header" />
              </tr>
            </thead>
            <draggable
              v-if="repair?.repairWorks"
              tag="tbody"
              v-model="repair.repairWorks"
              item-key="index"
              handle=".drag-handle"
              :disabled="props.readonly"
            >
              <template #item="{ element, index }">
                <tr>
                  <td v-if="tableHeaders.includes('Name')" class="v-data-table__td v-data-table-column--align-start" :class="{ 'pl-0': !props.readonly }">
                    <div class="d-flex align-center">
                      <div v-if="!props.readonly" class="drag-handle opacity-50 pr-1">
                        <v-icon>mdi-drag-vertical</v-icon>
                      </div>
                      <div>
                        {{ element.name }}
                      </div>
                    </div>
                  </td>
                  <td v-if="tableHeaders.includes('Date')" class="v-data-table__td v-data-table-column--align-start">
                    {{ `${moment(element.createdAt).format("YYYY-MM-DD HH:mm")} (${element.createdBy})` }}
                  </td>

                  <td v-if="!props.readonly" class="v-data-table__td v-data-table-column--align-end">
                    <v-btn
                      @click.stop="() => deleteWorkItem(index)"
                      icon
                      density="compact"
                      size="small"
                      variant="text"
                      class="ml-2"
                    >
                      <v-icon size="small">mdi-close-thick</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>

      <div v-if="!repair?.repairWorks?.length" class="text-body-2 text-disabled text-left pl-4 pa-2">
        No work items linked yet
      </div>
    </div>

    <div v-if="!props.readonly" class="d-flex d-flex align-center mt-4">
      <v-autocomplete
        v-model="selectedWorkItem"
        label="Add work item"
        item-title="name"
        item-value="repairWorkTypeId"
        return-object
        :items="workItems"
        density="compact"
        max-width="40%"
        class="select"
        filterable
        clearable
        no-data-text="No work items found"
        hide-details
        :loading="workItemsLoading"
        @update:modelValue="onSelect"
        ref="autocomplete"
        :error="!!errors.work"
        :error-messages="errors.work"
        :disabled="creatingProcess"
      >
        <template v-slot:selection="{ item }">
          <span class="itemWork">{{ item.raw.name }}</span>
        </template>
      </v-autocomplete>

      <v-text-field
        v-if="selectedWorkItem && !selectedWorkItem.repairWorkTypeId"
        v-model="newWorkItemName"
        label="Work item name"
        density="compact"
        hide-details
        :error="!!newItemError"
        max-width="40%"
        class="ml-5 pt-0"
        :disabled="creatingProcess"
      ></v-text-field>

      <div>
        <v-btn
          :loading="creatingProcess"
          :disabled="creatingProcess"
          class="ml-2"
          density="comfortable"
          size="small"
          icon="mdi-plus"
          variant="elevated"
          color="white"
          @click="addWorkItemToRepair"
        ></v-btn>
      </div>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import draggable from "vuedraggable";
import moment from "moment";
import { ref, watch } from "vue";
import axios, { CancelTokenSource } from "axios";
import RepairWork from "@/types/RepairWork";
import repairResource from "@/resources/RepairResource";
import Repair from "@/types/Repair";
import RepairWorkType from "@/types/RepairWorkType";
import authService from "@/services/AuthService";

const emit = defineEmits(["close:editRepair", "select", "changeName"]);
const props = withDefaults(
  defineProps<{
    repair?: Repair | null;
    readonly?: boolean;
    errors?: { work: string };
    isTitle?: boolean;
    newAsLocal?: boolean;
    tableHeaders?: string[];
  }>(),
  {
    repair: null,
    readonly: false,
    isTitle: true,
    newAsLocal: true,
    errors: () => ({ work: "" }),
    tableHeaders: () => ["Name", "Date"],
  }
);

const workItemsLoading = ref(false);
let workItemsToken: CancelTokenSource | undefined = undefined;
const workItems = ref<RepairWorkType[]>([]);
const selectedWorkItem = ref<RepairWorkType | null>(null);

const creatingProcess = ref(false);
const newWorkItemName = ref("");
const newItemError = ref("");

const autocomplete = ref(null);
const onSelect = () => {
  //@ts-ignore
  autocomplete?.value?.blur();
  props.errors.work = "";
};

const deleteWorkItem = (index: number) => {
  if (!props.repair) return;
  props.repair.repairWorks = props.repair.repairWorks.filter((v: RepairWork, ind) => index !== ind);
};

const getWorkItems = () => {
  if (workItemsToken) {
    workItemsToken.cancel();
  }

  workItemsToken = axios.CancelToken.source();
  workItemsLoading.value = true;
  repairResource
    .getRepairWorkTypes(workItemsToken)
    .then((resp) => {
      workItems.value = [
        ...resp.data,
        {
          repairWorkTypeId: 0,
          createdAt: new Date(),
          createdBy: authService.authInfo.username || "",
          name: "New work item",
        },
      ];
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      workItemsLoading.value = false;
      workItemsToken = undefined;
    });
};

watch(newWorkItemName, () => {
  if (newWorkItemName.value && newItemError.value) {
    newItemError.value = "";
  }
});

watch(selectedWorkItem, () => {
  emit("select", selectedWorkItem.value);
});

watch(
  () => props.repair,
  () => {
    if (!props.repair) return;
    getWorkItems();
  },
  { immediate: true }
);

const clearWorkItemSelect = () => {
  selectedWorkItem.value = null;
  newWorkItemName.value = "";
  newItemError.value = "";
};

const addWorkItemToRepair = () => {
  props.errors.work = "";
  if (selectedWorkItem.value && !selectedWorkItem.value.repairWorkTypeId && !newWorkItemName.value) {
    newItemError.value = "Field is required";
    return;
  }
  if (selectedWorkItem.value) {
    if (props.newAsLocal || selectedWorkItem.value.repairWorkTypeId) {
      props.repair?.repairWorks?.push({
        repairWorkId: 0,
        repairWorkTypeId: selectedWorkItem.value.repairWorkTypeId,
        name: newWorkItemName.value || selectedWorkItem.value.name,
        createdAt: new Date(),
        createdBy: authService.authInfo.username || "",
      });
      clearWorkItemSelect();
    } else {
      creatingProcess.value = true;
      repairResource
        .addRepairWorkType({ name: newWorkItemName.value || selectedWorkItem.value.name })
        .then((resp) => {
          props.repair?.repairWorks?.push({ repairWorkId: 0, ...resp.data });
        })
        .catch(repairResource.defaultErrorHandler)
        .finally(() => {
          clearWorkItemSelect();
          creatingProcess.value = false;
        });
    }
  }
};
</script>
<style scoped>
.work-items-container {
  max-width: 100%;
  overflow: auto;
}

.drag-handle {
  cursor: move;
  line-height: 1;
}

.itemWork {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:deep(.v-field--focused) .v-autocomplete__selection {
  width: 30%;
}
</style>
