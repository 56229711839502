<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newWork()" test-id="new-user"> New Work </v-btn>
        </div>
      </v-card-title>
      <div class="work-items-container">
        <div class="v-table v-table--has-top v-table--has-bottom v-table--density-compact v-data-table">
          <div class="v-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th class="pl-6">ID</th>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Author</th>
                </tr>
              </thead>
              <draggable
                v-if="repairWorksItems.length"
                tag="tbody"
                v-model="repairWorksItems"
                item-key="index"
                handle=".drag-handle"
                @end="onDragEnd"
              >
                <template #item="{ element }">
                  <tr @click="rowClick(element)" class="cursor-pointer">
                    <td>
                      <div class="d-flex align-center">
                        <div class="drag-handle opacity-50 pr-1">
                          <v-icon>mdi-drag-vertical</v-icon>
                        </div>
                        <div>
                          {{ element.repairWorkTypeId }}
                        </div>
                      </div>
                    </td>
                    <td>{{ element.name }}</td>

                    <td>{{ `${moment(element.createdAt).format("YYYY-MM-DD HH:mm")}` }}</td>

                    <td>{{ `${element.createdBy}` }}</td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>

        <div v-if="!repairWorksItems?.length" class="text-body-2 text-disabled text-center pl-4 pa-2">
          No work items linked yet
        </div>
      </div>
      <v-overlay
        contained
        :model-value="loading"
        opacity="0.46"
        z-index="109999"
        persistent
        class="d-flex align-center justify-center"
      >
        <v-progress-circular indeterminate color="primary" size="64" />
      </v-overlay>
    </v-card>

    <EditRepairWork v-model="repairWorkToEdit" v-on:updated="reload" />
  </v-container>
</template>

<script setup lang="ts">
import repairResource from "@/resources/RepairResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import RepairWorkType from "@/types/RepairWorkType";
import EditRepairWork from "@/components/repairs/EditRepairWork.vue";
import { ref, onBeforeMount } from "vue";
import draggable from "vuedraggable";

const props = withDefaults(defineProps<{ initData: { repairWorkTypeId: number } | null }>(), {
  initData: null,
});

const repairWorksItems = ref<RepairWorkType[]>([]);
const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
let orderCancelToken: CancelTokenSource | undefined = undefined;

const repairWorkToEdit = ref<RepairWorkType | null>(null);

onBeforeMount(() => {
  getData();
});

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    repairResource
      .getRepairWorkTypes(cancelToken)
      .then((resp) => {
        repairWorksItems.value = resp.data;

        const initRepairWorkTypeId = props.initData?.repairWorkTypeId;
        if (initRepairWorkTypeId) {
          const workToEdit = resp.data.find(({ repairWorkTypeId }) => repairWorkTypeId === initRepairWorkTypeId);
          if (workToEdit) rowClick(workToEdit);
        }
      })
      .catch(repairResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const reload = () => {
  getData();
};

const rowClick = (item: RepairWorkType) => {
  repairWorkToEdit.value = Object.assign({}, item);
};

const newWork = () => {
  repairWorkToEdit.value = {
    repairWorkTypeId: 0,
    name: "",
    description: "",
  } as RepairWorkType;
};

const onDragEnd = (event: any) => {
  const newDraggableIndex = event.newDraggableIndex;
  const oldDraggableIndex = event.oldDraggableIndex;

  // Cancel existing request
  if (orderCancelToken) {
    orderCancelToken.cancel();
  }

  orderCancelToken = axios.CancelToken.source();

  repairResource
    .setWorkTypesDisplayOrder(
      repairWorksItems.value.map(({ repairWorkTypeId }) => repairWorkTypeId),
      orderCancelToken
    )
    .catch((e: any) => {
      const item = repairWorksItems.value[newDraggableIndex];
      repairWorksItems.value.splice(newDraggableIndex, 1);
      repairWorksItems.value.splice(oldDraggableIndex, 0, item);

      repairResource.defaultErrorHandler(e);
    })
    .finally(() => {
      orderCancelToken = undefined;
    });
};
</script>
<style scoped>
.drag-handle {
  cursor: move;
  line-height: 1;
}
</style>
