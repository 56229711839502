<template>
  <div>
    <v-data-table
      density="compact"
      :headers="headers"
      :items="issues || []"
      :disable-pagination="true"
      :hide-default-footer="true"
      items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #no-data>
        <div class="text-body-2 text-disabled text-left">No issues linked yet</div>
      </template>

      <template v-slot:[`item.issueDeviceStatus`]="{ item }" v-if="props.isIssueDeviceStatus">
        <div v-if="isIssueDeviceStatus" :class="getIssueDeviceStatusColor((item as RepairIssue).issueDeviceStatus )">
          {{ getIssueDeviceStatusName((item as RepairIssue).issueDeviceStatus) }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <div class="d-flex justify-end">
          <v-btn
            link
            :href="`/support/issues/${item.issueId}`"
            @click="$event.stopPropagation()"
            target="_blank"
            icon
            title="Open in new tab"
            density="compact"
            size="small"
            variant="text"
          >
            <v-icon size="small">mdi-open-in-new</v-icon>
          </v-btn>

          <v-btn
            @click.stop="() => deleteRelatedIssue(index)"
            icon
            density="compact"
            size="small"
            variant="text"
            class="ml-2"
          >
            <v-icon size="small">mdi-close-thick</v-icon>
          </v-btn>
        </div>
      </template>

      <template #bottom></template>
    </v-data-table>

    <div v-if="canViewIssue" class="mt-2">
      <div class="d-flex align-center issue-select">
        <IssueSelect
          :key="issueSelectKey"
          @create="addNewIssue"
          @change="(v:any) => (selectedIssue = v)"
          :hint="
            props.isHint
              ? 'Select an existing issue or create a new issue and automatically link repair and device id to the issue.'
              : ''
          "
          label="Link existing issue or create a new one"
          :menuProps="{ maxWidth: '770' }"
          @update:title="(v) => (issueTitle = v)"
          :noFilter="true"
          :isLoading="creating"
        />
        <v-btn
          class="ml-2"
          density="comfortable"
          size="small"
          icon="mdi-plus"
          variant="elevated"
          color="white"
          :disabled="creating"
          @click="addIssueToRepair"
        ></v-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { computed, ref } from "vue";
import Issue from "@/types/Issue";
import RepairIssue from "@/types/RepairIssue";
import IssueSelect from "@/components/common/IssueSelect.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import IssueHelper from "@/helpers/issueHelper";
import issueResource from "@/resources/IssueResource";
import type { VDataTable } from "vuetify/components";
import RepairTemplateIssue from "@/types/RepairTemplateIssue";
import axios, { CancelTokenSource } from "axios";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["update"]);
const props = withDefaults(
  defineProps<{ issues?: (RepairIssue | RepairTemplateIssue)[] | null; isIssueDeviceStatus?: boolean; isHint: boolean }>(),
  {
    issues: null,
    isIssueDeviceStatus: false,
    isHint: true,
  }
);
const creating = ref(false);
let creatingCancelToken: CancelTokenSource | undefined = undefined;

const selectedIssue = ref<Issue | null>(null);
const issueSelectKey = ref<number>(new Date().getTime());

const headers: ReadonlyHeaders = props.isIssueDeviceStatus
  ? [
      { title: "ID", key: "issueId", align: "start", sortable: false },
      { title: "Title", key: "name", sortable: false },
      { title: "Device issue status", key: "issueDeviceStatus", sortable: false },
      { title: "", key: "actions", sortable: false },
    ]
  : [
      { title: "ID", key: "issueId", align: "start", sortable: false },
      { title: "Title", key: "name", sortable: false },
      { title: "", key: "actions", sortable: false },
    ];
const issueTitle = ref("");

const getIssueDeviceStatusName = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusDisplayName(status);
};

const getIssueDeviceStatusColor = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusColor(status);
};

const canAddIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddIssues);
});

const canViewIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewIssues);
});

const deleteRelatedIssue = (index: number) => {
  if (!props.issues) return;
  emit(
    "update",
    props.issues?.filter((_, ind) => index !== ind)
  );
};

const clearIssueSelect = () => {
  selectedIssue.value = null;
  issueSelectKey.value = new Date().getTime();
  issueTitle.value = "";
};
const addIssueToRepair = () => {
  if (!props.issues) return;
  if (selectedIssue.value) {
    if (props.issues?.find((v: RepairIssue | RepairTemplateIssue) => v.issueId === selectedIssue.value?.issueId)) {
      infoMessageService.show(InfoMessageType.Warning, "The issue is connected already.");
      return;
    }
    props.issues.push({
      ...selectedIssue.value,
      issueDeviceStatus: IssueDeviceStatus.New,
    });
    clearIssueSelect();
  }

  if (issueTitle.value) {
    addNewIssue();
  }
};
const addNewIssue = () => {
  if (!canAddIssue.value || !issueTitle.value || !props.issues) {
    return;
  }

creating.value = true
  creatingCancelToken = axios.CancelToken.source();
  issueResource
    .addIssue(issueTitle.value, undefined, creatingCancelToken)
    .then((resp) => {
      const newIssueId = resp.data.issueId;
      if(!props.issues) return;
      props.issues.push({
        issueId: newIssueId,
        name: issueTitle.value,
        issueDeviceStatus: IssueDeviceStatus.New,
      });
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      creating.value = false;
      creatingCancelToken = undefined;
      clearIssueSelect();
    });
};
</script>
