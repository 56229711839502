<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <v-form ref="repairWorkForm" v-model="valid" lazy-validation>
        <PropEditor v-if="props.modelValue.repairWorkTypeId" name="Info">
          <div class="text-subtitle-2">
            <div><span class="info-label">ID:</span> {{ props.modelValue.repairWorkTypeId }}</div>
            <div v-if="props.modelValue.createdAt">
              <span class="info-label">Created:</span> {{ moment(props.modelValue.createdAt).format("lll") }}
            </div>
            <div v-if="props.modelValue.createdBy">
              <span class="info-label">Created by:</span> {{ props.modelValue.createdBy }}
            </div>
            <div v-if="props.modelValue.lastModifiedAt">
              <span class="info-label">Updated:</span> {{ moment(props.modelValue.lastModifiedAt).format("lll") }}
            </div>
            <div v-if="props.modelValue.lastModifiedBy">
              <span class="info-label">Updated by:</span> {{ props.modelValue.lastModifiedBy }}
            </div>
          </div>
        </PropEditor>

        <PropEditor name="Name">
          <v-text-field
            density="compact"
            variant="outlined"
            v-model="props.modelValue.name"
            :rules="nameRules"
          ></v-text-field>
        </PropEditor>
        <PropEditor name="Description">
          <v-textarea
            density="compact"
            variant="outlined"
            v-model="props.modelValue.description"
            autocomplete="off"
            persistent-hint
          ></v-textarea>
        </PropEditor>
      </v-form>

      <template v-slot:actions>
        <v-btn
          v-if="props.modelValue.repairWorkTypeId"
          color="secondary"
          @click="deleteRepairWorkConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import RepairWorkType from "@/types/RepairWorkType";
import repairResource from "@/resources/RepairResource";
import apiKeysResource from "@/resources/ApiKeysResource";
import ChangeManager from "@/services/ChangeManager";
import moment from "moment";
import { VForm } from "vuetify/components";
import { ref, computed, watch } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

const { setComponentQuery } = useComponentQuery();
const confirm = useConfirm();

const emit = defineEmits(["update:modelValue", "updated"]);

const props = withDefaults(defineProps<{ modelValue: RepairWorkType | null }>(), {
  modelValue: null,
});

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  (val: RepairWorkType | null, oldValue: RepairWorkType | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.repairWorkTypeId !== val.repairWorkTypeId,
      data: { repairWork: val },
      message: "You have unsaved Repair Work changes.",
      target: `repairWorkTypeId_${val?.repairWorkTypeId}`,
      onLeave: () => (showDialog.value = false),
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origRepairWork = { ...changesControl.value?.data?.origData?.repairWork };

  if (!changesControl.value || !origRepairWork) return;

  if (!ChangeManager.isObjectEqual(origRepairWork, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl?.value.deactivate();
};

watch(() => props.modelValue, setChangesStatus, { deep: true });
// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

watch(
  () => props.modelValue,
  () => setComponentQuery("repairWorkTypeId", props.modelValue?.repairWorkTypeId ? props.modelValue.repairWorkTypeId : null)
);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.repairWorkTypeId
      ? `${props.modelValue.name} (ID: ${props.modelValue.repairWorkTypeId})`
      : "New Work Type";
  }
  return heading;
});

const repairWorkForm = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref(true);
const loading = ref(false);
const nameRules = [
  (v: any) => !!v || "Name is required",
  (v: any) => v.length > 2 || "Repair work name must be at least 3 characters long",
  (v: any) => v.length < 250 || "Maximum Repair work character limit is 250",
];

const deleting = ref(false);

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }

  // Validate form
  const { valid } = await (repairWorkForm.value as InstanceType<typeof VForm>).validate();

  if (valid) {
    if (props.modelValue.repairWorkTypeId) {
      // Update Repair Work
      repairResource
        .updateRepairWorkType(props.modelValue)
        .then(() => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(repairResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New Repair Work
      repairResource
        .addRepairWorkType(props.modelValue)
        .then((resp) => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(repairResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deleteRepairWorkConfirm = () => {
  if (props.modelValue == null) return;

  confirm.show(`Delete Repair work '${props.modelValue.name}'?`).then((confirmed) => {
    if (confirmed) {
      deleteRepairWork();
    }
  });
};

const deleteRepairWork = () => {
  if (props.modelValue == null) return;

  deleting.value = true;
  repairResource
    .deleteRepairWorkType(props.modelValue.repairWorkTypeId)
    .then(() => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(apiKeysResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};
</script>
