<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newTemplate()" test-id="new-user">
            New Template
          </v-btn>
        </div>
      </v-card-title>
      <div class="work-items-container">
        <div class="v-table v-table--has-top v-table--has-bottom v-table--density-compact v-data-table">
          <div class="v-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th class="pl-6">ID</th>
                  <th>Name</th>
                  <th>Created</th>
                  <th>Author</th>
                  <th>Work types</th>
                  <th>Issues</th>
                </tr>
              </thead>
              <draggable v-if="repairTemplatesItems.length" tag="tbody" v-model="repairTemplatesItems" item-key="index"
                handle=".drag-handle" @end="onDragEnd">
                <template #item="{ element }">
                  <tr @click="rowClick(element)" class="cursor-pointer">
                    <td>
                      <div class="d-flex align-center">
                        <div class="drag-handle opacity-50 pr-1">
                          <v-icon>mdi-drag-vertical</v-icon>
                        </div>
                        <div>
                          {{ element.repairTemplateId }}
                        </div>
                      </div>
                    </td>
                    <td>{{ element.name }}</td>

                    <td>{{ `${moment(element.createdAt).format("YYYY-MM-DD HH:mm")}` }}</td>

                    <td>
                      {{ `${element.createdBy}` }}
                    </td>
                    <td>{{ `${element.repairWorkTypes.length}` }}</td>
                    <td>{{ `${element.issues.length}` }}</td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>

        <div v-if="!repairTemplatesItems?.length" class="text-body-2 text-disabled text-center pl-4 pa-2">
          No repair template linked yet
        </div>
      </div>
      <v-overlay contained :model-value="loading" opacity="0.46" z-index="109999" persistent
        class="d-flex align-center justify-center">
        <v-progress-circular indeterminate color="primary" size="64" />
      </v-overlay>
    </v-card>

    <EditRepairTemplate v-model="repairTemplateToEdit" v-on:updated="reload" />
  </v-container>
</template>

<script setup lang="ts">
import repairResource from "@/resources/RepairResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import RepairTemplate from "@/types/RepairTemplate";
import RepairTemplateIssue from "@/types/RepairTemplateIssue";
import RepairWorkType from "@/types/RepairWorkType";
import EditRepairTemplate from "@/components/repairs/EditRepairTemplate.vue";
import { ref, onBeforeMount } from "vue";
import draggable from "vuedraggable";

const props = withDefaults(defineProps<{ initData: { repairTemplateId: number } | null }>(), {
  initData: null,
});

const repairTemplatesItems = ref<RepairTemplate[]>([]);
const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
let orderCancelToken: CancelTokenSource | undefined = undefined;

const repairTemplateToEdit = ref<RepairTemplate | null>(null);

onBeforeMount(() => {
  getData();
});

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    repairResource
      .getRepairTemplates(cancelToken)
      .then((resp) => {
        repairTemplatesItems.value = resp.data;

        const initRepairWorkTypeId = props.initData?.repairTemplateId;
        if (initRepairWorkTypeId) {
          const workToEdit = resp.data.find(({ repairTemplateId }) => repairTemplateId === initRepairWorkTypeId);
          if (workToEdit) rowClick(workToEdit);
        }
      })
      .catch(repairResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const reload = () => {
  getData();
};

const rowClick = (item: RepairTemplate) => {
  repairTemplateToEdit.value = JSON.parse(JSON.stringify(item));
};

const newTemplate = () => {
  repairTemplateToEdit.value = {
    repairTemplateId: 0,
    name: "",
    description: "",
    issues: [] as RepairTemplateIssue[],
    repairWorkTypes: [] as RepairWorkType[],
  } as RepairTemplate;
};

const onDragEnd = (event: any) => {
  const newDraggableIndex = event.newDraggableIndex;
  const oldDraggableIndex = event.oldDraggableIndex;

  // Cancel existing request
  if (orderCancelToken) {
    orderCancelToken.cancel();
  }

  orderCancelToken = axios.CancelToken.source();

  repairResource
    .setRepairTemplateOrder(
      repairTemplatesItems.value.map(({ repairTemplateId }) => repairTemplateId),
      orderCancelToken
    )
    .catch((e: any) => {
      const item = repairTemplatesItems.value[newDraggableIndex];
      repairTemplatesItems.value.splice(newDraggableIndex, 1);
      repairTemplatesItems.value.splice(oldDraggableIndex, 0, item);

      repairResource.defaultErrorHandler(e);
    })
    .finally(() => {
      orderCancelToken = undefined;
    });
};
</script>
<style scoped>
.drag-handle {
  cursor: move;
  line-height: 1;
}
</style>
