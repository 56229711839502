<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:heading-menu>
        <DeviceMenu v-model="props.modelValue.deviceId" :dark="true" size="small" />
      </template>
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow :show-arrows="false" color="primary" slider-color="primary">
          <v-tab value="device">Device</v-tab>
          <v-tab value="comments">
            <span>
              Comments
              <v-badge
                density="compact"
                :color="tab === 'comments' ? 'primary' : 'badge'"
                :content="commentCount"
                inline
                class="ml-1"
                v-if="commentCount"
              />
            </span>
          </v-tab>
          <v-tab v-if="canViewIssues" value="issues">
            Issues
            <v-badge
              :color="tab === 'issues' ? 'primary' : 'badge'"
              :content="issueCount"
              inline
              class="ml-1"
              v-if="issueCount"
            />
          </v-tab>
          <v-tab value="service" v-if="canViewRepairs || canAddRepair || canViewManufacturerWarranty">Service</v-tab>

          <v-tab v-if="isProDevice" value="mobileNetProvider">Network Provider</v-tab>
          <v-tab value="history">History</v-tab>
          <v-tab v-if="canViewLogs" value="logs">Logs</v-tab>
        </v-tabs>
      </template>

      <v-window
        :transition="false"
        v-model="tab"
        :touch="false"
        :hide-slider="false"
        class="full-height-tabs-wrap tabs-w-100"
      >
        <!-- DEVICE -->
        <v-tabs-window-item :reverse-transition="false" value="device" :transition="false">
          <v-form ref="deviceForm" v-model="valid" lazy-validation>
            <PropEditor v-if="props.modelValue.deviceId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Device ID:</span> {{ props.modelValue.deviceId }}</div>
                <div><span class="info-label">Device type:</span> {{ getDeviceTypeName() }}</div>
                <div v-if="isProDevice">
                  <div><span class="info-label">Firmware:</span> {{ firmwareVer }}</div>
                  <div><span class="info-label">Bootloader:</span> {{ bootloaderVer }}</div>
                </div>
                <div v-if="props.modelValue.createdDate">
                  <span class="info-label">Created:</span>
                  {{ moment(props.modelValue.createdDate).format("lll") }}
                </div>

                <div v-if="props.modelValue && props.modelValue.createdDate !== props.modelValue.updatedDate">
                  <span class="info-label">Updated:</span>
                  {{ moment(props.modelValue.updatedDate).format("lll") }}
                </div>

                <div v-if="props.modelValue.lastSeenDate">

                  <span class="info-label">Last seen:</span>
                  {{ moment(props.modelValue.lastSeenDate).format("lll") }}
                </div>
                <div v-if="isProDevice">
                  <span
                    v-if="!deviceIdentity"
                    class="text-decoration-underline cursor-pointer"
                    @click="fetchDeviceIdentity()"
                    >Show details</span
                  >
                  <div v-if="deviceIdentity">
                    <v-divider class="my-3" />
                    <div>
                      <span class="info-label">Firmware:</span>
                      {{ deviceIdentity.firmwareVersion }}
                    </div>
                    <div>
                      <span class="info-label">Bootloader:</span>
                      {{ deviceIdentity.bootloaderVersion }}
                    </div>
                    <div>
                      <span class="info-label">Boot address:</span>
                      {{ deviceIdentity.bootAddress }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Family:</span>
                      {{ deviceIdentity.mcuId.family }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Core:</span>
                      {{ deviceIdentity.mcuId.core }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Revision:</span>
                      {{ deviceIdentity.mcuId.revision }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Package pin count:</span>
                      {{ deviceIdentity.mcuId.packagePinCount }}
                    </div>
                    <div>
                      <span class="info-label">MCU - RAM size:</span>
                      {{ deviceIdentity.mcuId.ramSize }} bytes
                    </div>
                    <div>
                      <span class="info-label">MCU - Flash size:</span>
                      {{ deviceIdentity.mcuId.flashSize }} bytes
                    </div>
                    <div>
                      <span class="info-label">Modem firmware revision:</span>
                      {{ deviceIdentity.modemFirmwareRevision }}
                    </div>
                    <div>
                      <span class="info-label">Modem IMEI:</span>
                      {{ deviceIdentity.modemImei }}
                    </div>
                    <div>
                      <span class="info-label">SIM ICC:</span>
                      {{ deviceIdentity.simIcc }}
                    </div>
                    <div>
                      <span class="info-label">Has bluetooth:</span>
                      {{ deviceIdentity.hasBluetooth }}
                    </div>
                    <div>
                      <span class="info-label">LCD driver:</span>
                      {{ deviceIdentity.lcdDriverId }}
                    </div>
                    <div>
                      <span class="info-label">Touch controller:</span>
                      {{ deviceIdentity.touchControllerId }}
                    </div>
                    <div>
                      <span class="info-label">Last updated:</span>
                      {{ moment(deviceIdentity.dateModified).format("lll") }}
                    </div>
                  </div>
                </div>
                <div v-if="canViewFwUpdateUrl">
                  <v-divider class="my-3" />
                  <div>
                    <span class="info-label valign-center">Firmware update:</span>
                    <span v-if="!fwUpdateUrl" class="text-decoration-underline cursor-pointer" @click="getFwUpdateUrl()"
                      >Show URL</span
                    >
                    <span v-if="fwUpdateUrl">
                      <a :href="fwUpdateUrl" color="primary" target="_blank">{{ fwUpdateUrl }}</a>
                      <v-btn density="compact" @click="copyToClipboard(fwUpdateUrl)" size="small" icon class="ml-2">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </div>
              </div>
            </PropEditor>
            <PropEditor name="Last known location" valign="top" v-if="canViewLastLocation">
              <DeviceTelemetryView v-model="deviceId" />
            </PropEditor>
            <PropEditor name="Name" desc="">
              <v-text-field
                v-model="props.modelValue.deviceName"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
              ></v-text-field>
            </PropEditor>

            <PropEditor name="Status">
              <v-select
                :list-props="{ density: 'compact' }"
                attach
                v-model="props.modelValue.status"
                :items="deviceStatus"
                :variant="isAdmin ? 'outlined' : 'plain'"
                :readonly="!isAdmin"
                density="compact"
                item-color=""
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item.title }}</span>
                </template>
                <template v-slot:item="{ props }">
                  <v-list-item v-bind="props"> </v-list-item>
                </template>
              </v-select>
            </PropEditor>
            <PropEditor v-if="!canViewCustomers" name="Customer ID" desc="Valid customer ID number, or 0 (zero) for none.">
              <v-text-field
                v-model="props.modelValue.customerId"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
                :rules="customerIdRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor v-if="canViewCustomers" name="Customer">
              <CustomerSelect v-model="props.modelValue.customerId" :canEdit="canEditDevice" />
            </PropEditor>
            <PropEditor name="Subscription end" desc="">
              <v-row>
                <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                  <v-menu
                    v-model="subsEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="33"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="subscriptionEndDate"
                        density="compact"
                        :variant="canEditDevice ? 'outlined' : 'solo'"
                        :flat="!canEditDevice"
                        :readonly="!canEditDevice"
                        :error="!subscriptionEndDate || isNaN(Date.parse(subscriptionEndDate))"
                        v-bind="props"
                        :hide-details="true"
                      >
                        <template v-slot:prepend>
                          <v-icon class="mr-n2">mdi-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-if="canEditDevice"
                      :modelValue="
                        moment(subscriptionEndDate, 'YYYY-MM-DD', true).isValid()
                          ? moment(subscriptionEndDate).toDate()
                          : null
                      "
                      @update:modelValue="(v) => (subscriptionEndDate = moment(v).format('YYYY-MM-DD'))"
                      :hide-header="true"
                      no-title
                      scrollable
                      :show-current="moment().format('YYYY-MM-DD')"
                      @update:model-value="subsEndDateMenu = false"
                      show-adjacent-months
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                  <v-menu
                    ref="subsEndTimeMenuRef"
                    v-model="subsEndTimeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="subscriptionEndTime"
                    :nudge-right="33"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="subscriptionEndTime"
                        readonly
                        density="compact"
                        :variant="canEditDevice ? 'outlined' : 'solo'"
                        :flat="!canEditDevice"
                        v-bind="props"
                        :hide-details="true"
                        color="primary"
                      >
                        <template v-slot:prepend>
                          <v-icon class="mr-n2">mdi-clock-time-four-outline</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="subsEndTimeMenu && canEditDevice"
                      v-model="subscriptionEndTime"
                      no-title
                      format="24hr"
                      full-width
                      :hide-header="true"
                      @update:hour="onHour"
                      @update:minute="saveTimeChanges"
                      color="primary"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Blocked" desc="">
              <v-switch v-model="props.modelValue.isBlocked" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor name="Debug mode" desc="">
              <v-switch v-model="props.modelValue.isDebugMode" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor
              v-if="isProDevice && canAllowUpdates"
              name="Allow updates"
              desc="Device is allowed to recieve firmware updates."
            >
              <v-switch v-model="props.modelValue.isUpdateAllowed" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor name="Notes">
              <v-textarea
                v-model="props.modelValue.notes"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
              ></v-textarea>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- COMMENTS -->
        <v-tabs-window-item :reverse-transition="false" value="comments" :transition="false">
          <DeviceComments :deviceId="deviceId" @update="getCommentCount" />
        </v-tabs-window-item>

        <!-- ISSUES -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewIssues"
          value="issues"
          :transition="false"
          class="fill-height"
        >
          <AssociatedIssues :deviceId="props.modelValue.deviceId" @countUpdate="getIssueCount" />
        </v-tabs-window-item>

        <!-- SERVICE -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewRepairs || canAddRepair || canViewManufacturerWarranty"
          value="service"
          :transition="false"
          class="fill-height"
        >
          <DeviceService :device="props.modelValue" />
        </v-tabs-window-item>

        <!-- MOBILE NET PROVIDER -->
        <v-tabs-window-item :reverse-transition="false" v-if="isProDevice" value="mobileNetProvider" :transition="false">
          <DeviceMobileNetProvider :deviceId="mobileNetProviderStatusDeviceId" />
          <MobileNetworkUsage :deviceId="mobileNetProviderStatusDeviceId" class="mt-5" />
        </v-tabs-window-item>

        <!-- HYSTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" :transition="false">
          <DeviceChangeHistory :deviceId="changeHistoryDeviceId" />
        </v-tabs-window-item>
        <!-- LOGS -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewLogs"
          value="logs"
          :transition="false"
          class="fill-height"
        >
          <DeviceLogsView :deviceId="mobileNetProviderStatusDeviceId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteDevice && props.modelValue.deviceId"
          color="secondary"
          @click="deleteDeviceConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditDevice"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import CustomerSelect from "@/components/common/CustomerSelect.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import Device from "@/types/Device";
import DeviceHelper from "@/helpers/deviceHelper";
import deviceResource from "@/resources/DeviceResource";
import { DeviceType } from "@/types/DeviceType";
import DeviceTelemetryView from "@/components/devices/DeviceTelemetryView.vue";
import DeviceChangeHistory from "@/components/devices/DeviceChangeHistory.vue";
import DeviceMobileNetProvider from "@/components/devices/DeviceMobileNetProvider.vue";
import DeviceLogsView from "@/components/devices/DeviceLogsView.vue";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import DeviceComments from "@/components/devices/DeviceComments/DeviceComments.vue";
import AssociatedIssues from "@/components/devices/AssociatedIssues.vue";
import DeviceService from "@/components/service/DeviceService.vue";

import DeviceVersion from "@/types/DeviceVersion";
import DeviceIdentityModel from "@/types/DeviceIdentity";
import ChangeManager from "@/services/ChangeManager";
import MobileNetworkUsage from "@/components/devices/MobileNetworkUsageView.vue";
import { VForm } from "vuetify/components";
import { ref, computed, watch, onActivated, onDeactivated } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

const emit = defineEmits(["update:modelValue", "updated"]);

const props = withDefaults(
  defineProps<{
    readonly modelValue: Device | null;
    readonly deviceInitTab?: string | null;
    readonly hasUrlUpdate?: boolean;
  }>(),
  {
    deviceInitTab: null,
    modelValue: null,
    hasUrlUpdate: true,
  }
);

const confirm = useConfirm();

const { setComponentQuery } = useComponentQuery();

const commentCount = ref(0);
const issueCount = ref(0);

const deviceIdentity = ref<DeviceIdentityModel | null>(null);
const componentActive = ref(false);

onActivated(() => {
  componentActive.value = true;
});

onDeactivated(() => {
  componentActive.value = false;
});

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value: boolean) {
    if (value) {
      emit("update:modelValue", value);
    } else {
      emit("update:modelValue", null);
    }
  },
});

const disabledSubmitBtn = computed(() => !changesControl.value?.data?.isChanged || !deviceForm.value);

const deleting = ref(false);
const fwInfo = ref<DeviceVersion | null>(null);
const firmwareVer = ref("");
const bootloaderVer = ref("");
const fwUpdateUrl = ref<string | null>(null);

const deviceForm = ref<InstanceType<typeof VForm> | null>(null);

const deviceStatus = DeviceHelper.getDeviceFilterByStatus().map((v) => ({ ...v, title: v.text }));

const deviceId = ref<number | undefined>(undefined);
const changeHistoryDeviceId = ref<number | null>(null);
const mobileNetProviderStatusDeviceId = ref<number | null>(null);
const logsDeviceId = ref<number | null>(null);

const tab = ref<string | null>(null);

const subsEndDateMenu = ref(false);
const subscriptionEndDate = ref("");

const subsEndTimeMenu = ref(false);
const subscriptionEndTime = ref("");
const saveTimeChanges = () => {
  subsEndTimeMenu.value = false;
};

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.deviceId ? `Device ID: ${props.modelValue.deviceId}` : "New device";
  }
  return heading;
});

const valid = ref(true);
const loading = ref(false);

const customerIdRules = [(v: string) => /^\d+$/.test(v) || "Customer ID should be valid number"];

const canViewLastLocation = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDeviceLocation));
const canEditDevice = computed(() => userProfileService.hasPermission(UserPermissionType.EditDevices));
const canDeleteDevice = computed(() => userProfileService.hasPermission(UserPermissionType.DeleteDevices));
const canViewLogs = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs));
const isAdmin = computed(() => userProfileService.currentUser?.isAdministrator);

const isProDevice = computed(
  () =>
    props.modelValue && (props.modelValue.type == DeviceType.GpsRadarPro || props.modelValue.type == DeviceType.GpsRadarProX)
);

const canAllowUpdates = computed(
  () => isAdmin.value || userProfileService.hasPermission(UserPermissionType.EditAllowUpdateProperty)
);

const canViewFwUpdateUrl = computed(() => userProfileService.hasPermission(UserPermissionType.ViewFirmwareUpdateUrl));

const canViewIssues = computed(() => userProfileService.hasPermission(UserPermissionType.ViewIssues));

const canViewCustomers = computed(() => userProfileService.hasPermission(UserPermissionType.ViewCustomers));

const canViewRepairs = computed(() => userProfileService.hasPermission(UserPermissionType.ViewRepairs));

const canAddRepair = computed(() => userProfileService.hasPermission(UserPermissionType.AddRepairs));

const canViewManufacturerWarranty = computed(() => userProfileService.hasPermission(UserPermissionType.ViewManufacturerWarranty));

watch(
  () => props.modelValue,
  function onDeviceChange() {
    fwUpdateUrl.value = null;

    // set subscription end date and time
    if (props.modelValue) {
      deviceId.value = props.modelValue.deviceId;

      if (props.modelValue.subscriptionEndDate) {
        const dt = moment(props.modelValue.subscriptionEndDate);
        subscriptionEndDate.value = dt.format("YYYY-MM-DD");
        subscriptionEndTime.value = dt.format("HH:mm");
      } else {
        subscriptionEndDate.value = "";
        subscriptionEndTime.value = "";
      }

      if (isProDevice.value) {
        getDeviceFirmware();
      }
      if (props.deviceInitTab) {
        tab.value = props.deviceInitTab || null;
      }

      getCommentCount();
      getIssueCount();
    } else {
      if (props.hasUrlUpdate) {
        setComponentQuery("deviceTab", null);
      }
      tab.value = null;
      deviceId.value = undefined;
      changeHistoryDeviceId.value = null;
      mobileNetProviderStatusDeviceId.value = null;
      logsDeviceId.value = null;
      deviceIdentity.value = null;
    }

    if (props.hasUrlUpdate && componentActive.value) {
      setComponentQuery("deviceId", props.modelValue?.deviceId ? props.modelValue?.deviceId : null);
    }
  }
);

watch(tab, function onTabChange(val: string | null) {
  if (!props.modelValue) return;
  if (val === "history") {
    changeHistoryDeviceId.value = props.modelValue.deviceId;
  } else if (val === "mobileNetProvider") {
    mobileNetProviderStatusDeviceId.value = props.modelValue.deviceId;
  } else if (val === "logs") {
    mobileNetProviderStatusDeviceId.value = props.modelValue.deviceId;
  }

  if (props.modelValue?.deviceId) {
    if (props.hasUrlUpdate) {
      setComponentQuery("deviceTab", val);
    }
  }
});

const getDeviceTypeName = () =>
  computed(() => (props.modelValue ? DeviceHelper.getDeviceTypeDisplayName(props.modelValue?.type) : undefined));

const submit = async () => {
  if (props.modelValue === null) return;

  // Validate form
  const { valid } = await (deviceForm.value as InstanceType<typeof VForm>).validate();
  if (valid) {
    if (props.modelValue.deviceId) {
      // set subscription end date
      if (subscriptionEndDate.value) {
        props.modelValue.subscriptionEndDate = moment(
          subscriptionEndDate.value ? subscriptionEndDate.value + "T" + subscriptionEndTime.value : "00:00"
        ).toDate();
      } else {
        props.modelValue.subscriptionEndDate = undefined;
      }

      // Update
      loading.value = true;
      deviceResource
        .updateDevice(props.modelValue)
        .then((resp) => {
          showDialog.value = false;
          emit("updated", props.modelValue);
        })
        .catch(deviceResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New
      infoMessageService.show(InfoMessageType.Warning, "Creating new devices is not supported at the moment");
    }
  }
};

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  function setChangeManager(val: Device | null, oldValue: Device | null) {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.deviceId !== val.deviceId,
      data: { device: val },
      message: "You have unsaved Device changes.",
      target: `device_${val?.deviceId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

watch(
  () => props.modelValue,
  function checkChangesStatus() {
    setChangesStatus();
  },
  { deep: true }
);

watch(
  () => [subscriptionEndDate.value, subscriptionEndTime.value],
  function onChangeDT() {
    setChangesStatus();
  }
);

const onHour = (newHour: number) => {
  if (!subscriptionEndTime.value) return;
  const hour = newHour < 10 ? `0${newHour}` : newHour.toString();
  subscriptionEndTime.value = hour + subscriptionEndTime.value.slice(2);
};

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origDevice = changesControl.value?.data?.origData?.device;
  if (!changesControl.value || !origDevice) return;
  var dt = moment(origDevice.subscriptionEndDate);
  if (dt.format("YYYY-MM-DD") !== subscriptionEndDate.value || dt.format("HH:mm") !== subscriptionEndTime.value) {
    changesControl?.value.activate();
    return;
  }
  if (!ChangeManager.isObjectEqual(origDevice, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};
// end change management

const getDeviceFirmware = () => {
  firmwareVer.value = "loading...";
  bootloaderVer.value = "loading...";
  if (!props.modelValue) return;

  deviceResource
    .getLastKnownDeviceVersion(props.modelValue.deviceId)
    .then((resp) => {
      firmwareVer.value = resp.data.firmwareVersion;
      if (resp.data.bootMemAddress) {
        firmwareVer.value += ` (${resp.data.bootMemAddress})`;
      }
      bootloaderVer.value = resp.data.bootloaderVersion;
      fwInfo.value = resp.data;
    })
    .catch((e) => {
      if (e.response && e.response.status == 404) {
        firmwareVer.value = "unknown";
        bootloaderVer.value = "unknown";
        fwInfo.value = {} as DeviceVersion;
      } else {
        deviceResource.defaultErrorHandler(e);
        firmwareVer.value = "error";
      }
    });
};

const deleteDeviceConfirm = () => {
  if (!canDeleteDevice.value || props.modelValue == null) {
    return;
  }

  confirm
    .show(`Delete device '${props.modelValue.deviceName}' with ID ${props.modelValue.deviceId}?`, {
      width: 360,
    })
    .then((confirmed) => {
      if (confirmed) {
        deleteDevice();
      }
    });
};

const deleteDevice = () => {
  if (!canDeleteDevice.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  deviceResource
    .deleteDevice(props.modelValue.deviceId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const fetchDeviceIdentity = () => {
  if (!props.modelValue?.deviceId) {
    return;
  }
  deviceResource
    .getDeviceIdentityById(props.modelValue.deviceId)
    .then((resp) => {
      deviceIdentity.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler);
};

const getFwUpdateUrl = () => {
  if (!props.modelValue) return;

  deviceResource.getFwUpdateUrl(props.modelValue.deviceId).then((resp) => {
    if (resp.data) {
      fwUpdateUrl.value = resp.data;
    }
  });
};

const getIssueCount = () => {
  if (!props.modelValue) {
    return;
  }
  deviceResource
    .getIssueCount(props.modelValue.deviceId)
    .then((resp) => {
      if (resp.data) {
        issueCount.value = resp.data.total;
      }
    })
    .catch(deviceResource.defaultErrorHandler);
};

const getCommentCount = () => {
  if (!props.modelValue) {
    return;
  }
  deviceResource
    .getCommentCount(props.modelValue.deviceId)
    .then((resp) => {
      if (resp.data) {
        commentCount.value = resp.data.total;
      }
    })
    .catch(deviceResource.defaultErrorHandler);
};

const copyToClipboard = (content: string | null) => {
  if (content) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, "Copied to clipboard");
      })
      .catch((e) => {
        infoMessageService.show(InfoMessageType.Error, "Failed to copy content");
      });
  }
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};
</script>
