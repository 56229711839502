<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field v-model="searchTerm" append-inner-icon="mdi-magnify" color="primary" variant="underlined"
            label="Search" hide-details clearable @click:clear="search()" style="max-width" v-on:input="search()"
            v-on:keypress.enter="search(true)" test-id="search"></v-text-field>
          <v-btn size="x-small" variant="text" icon class="align-self-end ml-4" @click="reload" :disabled="loading"
            title="Refresh">
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="usersTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newUser()" test-id="new-user"> New user
          </v-btn>
          <v-menu v-if="isAdmin" location="bottom left">
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" density="compact" variant="text" class="ml-4">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportUserActivity(moment().subtract(1, 'year').year())">
                <v-list-item-title>{{ `Export user activity - ${moment().subtract(1, "year").year()}`
                  }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportUserActivity(moment().year())">
                <v-list-item-title>{{ `Export user activity - ${moment().year()}` }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>

      <v-data-table-server density="compact" :row-props="rowClass" :headers="selectedHeaders" :items="items"
        :items-length="total" v-model:sort-by="sortBy" :must-sort="true" hover :mobile="false" :loading="loading"
        :mobile-breakpoint="0" @click:row="(event: any, { item }: any) => rowClick(item)"
        @contextmenu:row="openContenxMenu">
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isAdministrator`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isAdministrator">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.groups`]="{ item }">
          <v-chip size="small" class="ma-1px cursor-pointer" v-for="group in item.userGroups"
            v-bind:key="group.userGroupId" @click.stop="userGroupToEdit = JSON.parse(JSON.stringify(group))">{{
            group.name }}</v-chip>
        </template>
        <template v-slot:[`item.lastLoginDate`]="{ item }">
          <span v-if="item.lastLoginDate">{{ moment(item.lastLoginDate).format("lll") }}</span>
        </template>
        <template v-slot:[`item.mfaType`]="{ item }">
          <span v-if="item.mfaType">{{ getMfaTypeName(item.mfaType) }}</span>
        </template>

        <template v-slot:bottom>
          <DataTableFooter v-model:page="page" :items="items" :itemsLength="total" v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]" />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" persistent opacity="0" :style="'z-index: 999 !important;'" />
    </v-card>

    <EditUser v-model="userToEdit" v-on:updated="reload" :initTab="userInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
    <EditUserGroup v-model="userGroupToEdit" v-on:updated="reload" :isDeleteBtn="false" />
  </v-container>
</template>

<script setup lang="ts">
import User from "@/types/User";
import userResource from "@/resources/UserResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import UserGroup from "@/types/UserGroup";
import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";
import UserHelper from "@/helpers/userHelper";
import userStorage from "@/services/UserStorageService";
import userGroupResource from "@/resources/UserGroupResource";
import commonHelper from "@/helpers/commonHelper";
import userProfileService from "@/services/UserProfileService";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import EditUserGroup from "@/components/users/EditUserGroup.vue";
import EditUser from "@/components/users/EditUser.vue";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { ref, computed, watch, nextTick, onMounted } from "vue";

const props = withDefaults(defineProps<{ initData: { userId: number; userGroupId: number; userTab: string } | null }>(), {
  initData: null,
});

const total = ref(0);
const items = ref<User[]>([]);
const loading = ref(false);
const userGroupToEdit = ref<UserGroup | null>(null);
const userInitTab = ref<string | null>(null);

const optionsStorageKey = ref("usersTable");
const itemsPerPage = ref<number>(userStorage.get(optionsStorageKey.value)?.itemsPerPage ?? 15);

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey.value)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey.value)?.sortBy
    : [{ key: "userId", order: true }]
);

const page = ref<number>(userStorage.get(optionsStorageKey.value)?.page ?? 1);
const searchTermStorageKey = ref("userTableSearchTerm");
const searchTerm = ref<string>(userStorage.get(searchTermStorageKey.value) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const userToEdit = ref<User | null>(null);

const isAdmin = computed(() => userProfileService.currentUser?.isAdministrator);

watch([itemsPerPage, sortBy, page], async () => {
  await nextTick();
  getData();
});

onMounted(() => {
  if (props.initData?.userId) {
    getInitUserById(props.initData?.userId);
  }
  if (props.initData?.userGroupId) {
    getInitUserGroupById(props.initData?.userGroupId);
  }
  getData();
});

const getInitUserGroupById = (userGroupId: number) => {
  userGroupResource
    .getUserGroupById(userGroupId)
    .then((resp) => {
      userGroupToEdit.value = resp.data;
    })
    .catch(userGroupResource.defaultErrorHandler);
};

const getInitUserById = (userId: number) => {
  userResource
    .getUserById(userId)
    .then((resp) => {
      userInitTab.value = props.initData?.userTab || null;
      userToEdit.value = resp.data;
    })
    .catch(userResource.defaultErrorHandler);
};

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", align: "start", key: "userId" },
  { title: "Username", key: "username" },
  { title: "Active", key: "isActive" },
  { title: "Admin", key: "isAdministrator" },
  { title: "Groups", key: "groups" },
  { title: "MFA", key: "mfaType" },
  { title: "Last sign in", key: "lastLoginDate" },
];

const contextMenuEventItem = ref<any>(null);
const openContenxMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const getMfaTypeName = (type: MultiFactorAuthType) => {
  return UserHelper.getMfaTypeDisplayName(type);
};

const newUser = () => {
  userToEdit.value = {
    userId: 0,
    username: "",
    password: "",
    email: "",
    isActive: true,
    mfaType: MultiFactorAuthType.None,
    userGroups: [] as UserGroup[],
    isPasswordUpdateRequired: true,
  } as User;
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey.value, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey.value, searchTerm.value);

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();
    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";

    userResource
      .getUsersPaged(itemsPerPage.value, page.value, searchTerm.value, sortBy.value[0].key, orderDesc, cancelToken)
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(userResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData();
  } else {
    searchThrottleTimer = setTimeout(() => getData(), 1000);
  }
};

const reload = () => {
  getData();
};

const rowClick = (item: User) => {
  if (!contextMenuEventItem.value) {
    userToEdit.value = Object.assign({}, item);
  }
};

const rowClass = ({ item }: { item: User }) => {
  return { class: { "cursor-default text-grey": !item.isActive, "cursor-default": item.isActive } };
};

const exportUserActivity = (year: string | number) => {
  window.open(`${commonHelper.apiHost}/export/user-activity?year=${year}`, "_blank");
};
</script>

<style scoped></style>
