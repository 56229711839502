<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <v-form ref="repairWorkForm" v-model="valid" lazy-validation>
        <PropEditor v-if="props.modelValue.repairTemplateId" name="Info">
          <div class="text-subtitle-2">
            <div><span class="info-label">ID:</span> {{ props.modelValue.repairTemplateId }}</div>
            <div v-if="props.modelValue.createdAt">
              <span class="info-label">Created:</span> {{ moment(props.modelValue.createdAt).format("lll") }}
            </div>
            <div v-if="props.modelValue.createdBy">
              <span class="info-label">Created by:</span> {{ props.modelValue.createdBy }}
            </div>
          </div>
        </PropEditor>

        <PropEditor name="Name">
          <v-text-field
            density="compact"
            variant="outlined"
            v-model="props.modelValue.name"
            :rules="nameRules"
          ></v-text-field>
        </PropEditor>

        <PropEditor name="Description">
          <v-textarea
            density="compact"
            variant="outlined"
            v-model="props.modelValue.description"
            autocomplete="off"
            persistent-hint
          ></v-textarea>
        </PropEditor>

        <PropEditor name="Work items">
          <RepairWorkItems
            :repair="(repairWorksWrap as Repair)"
            :isTitle="false"
            :tableHeaders="['Name']"
            :newAsLocal="false"
          />
        </PropEditor>

        <PropEditor
          name="Issues"
          desc="Select an existing issue or create a new issue and automatically link repair and device id to
                    the issue."
        >
          <div class="ml-4">
            <RepairIssue :issues="props.modelValue?.issues" @update="updateIssues" :isHint="false" />
          </div>
        </PropEditor>
      </v-form>

      <template v-slot:actions>
        <v-btn
          v-if="props.modelValue.repairTemplateId"
          color="secondary"
          @click="deleteRepairTemplateConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn">
          Submit
        </v-btn>
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import RepairWorkItems from "@/components/service/RepairWorkItems.vue";
import RepairIssue from "@/components/repairs/RepairIssue.vue";
import Repair from "@/types/Repair";
import RepairTemplateIssue from "@/types/RepairTemplateIssue";
import RepairTemplate from "@/types/RepairTemplate";
import repairResource from "@/resources/RepairResource";
import apiKeysResource from "@/resources/ApiKeysResource";
import ChangeManager from "@/services/ChangeManager";
import moment from "moment";
import { VForm } from "vuetify/components";
import { ref, computed, watch } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";
import RepairWorkType from "@/types/RepairWorkType";

const { setComponentQuery } = useComponentQuery();
const confirm = useConfirm();

const emit = defineEmits(["update:modelValue", "updated"]);

const props = withDefaults(defineProps<{ modelValue: RepairTemplate | null }>(), {
  modelValue: null,
});

const repairWorksWrap = ref<{ repairWorks: RepairWorkType[] }>({ repairWorks: [] });
watch(
  repairWorksWrap,
  () => {
    if (props.modelValue?.repairWorkTypes) {
      props.modelValue.repairWorkTypes = repairWorksWrap.value.repairWorks;
    }
  },
  { deep: true }
);

watch(
  () => props.modelValue,
  () => {
    repairWorksWrap.value.repairWorks = props.modelValue?.repairWorkTypes || [];
  }
);

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  (val: RepairTemplate | null, oldValue: RepairTemplate | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.repairTemplateId !== val.repairTemplateId,
      data: { repairTemplate: val },
      message: "You have unsaved Repair Template changes.",
      target: `repairTemplateId_${val?.repairTemplateId}`,
      onLeave: () => (showDialog.value = false),
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const originRepairTemplate = { ...changesControl.value?.data?.origData?.repairTemplate };

  if (!changesControl.value || !originRepairTemplate) return;

  if (!ChangeManager.isObjectEqual(originRepairTemplate, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl?.value.deactivate();
};

watch(() => props.modelValue, setChangesStatus, { deep: true });
// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

watch(
  () => props.modelValue,
  () => setComponentQuery("repairTemplateId", props.modelValue?.repairTemplateId ? props.modelValue.repairTemplateId : null)
);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.repairTemplateId
      ? `${props.modelValue.name} (ID: ${props.modelValue.repairTemplateId})`
      : "New Repair Template";
  }
  return heading;
});

const repairWorkForm = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref(true);
const loading = ref(false);
const nameRules = [
  (v: any) => !!v || "Name is required",
  (v: any) => v.length > 2 || "Repair template name must be at least 3 characters long",
  (v: any) => v.length < 250 || "Maximum Repair template character limit is 250",
];

const deleting = ref(false);

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }

  // Validate form
  const { valid } = await (repairWorkForm.value as InstanceType<typeof VForm>).validate();

  if (valid) {
    if (props.modelValue.repairTemplateId) {
      // Update Repair Template
      repairResource
        .updateRepairTemplate({
          ...props.modelValue,
          issueIds: props.modelValue.issues.map(({ issueId }) => issueId),
          repairWorkTypeIds: props.modelValue.repairWorkTypes.map(({ repairWorkTypeId }) => repairWorkTypeId),
        })
        .then(() => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(repairResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New Repair Template
      repairResource
        .addRepairTemplate({
          ...props.modelValue,
          issueIds: props.modelValue.issues.map(({ issueId }) => issueId),
          repairWorkTypeIds: props.modelValue.repairWorkTypes.map(({ repairWorkTypeId }) => repairWorkTypeId),
        })
        .then((resp) => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(repairResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deleteRepairTemplateConfirm = () => {
  if (props.modelValue == null) return;

  confirm.show(`Delete template '${props.modelValue.name}'?`).then((confirmed) => {
    if (confirmed) {
      deleteRepairTemplate();
    }
  });
};

const deleteRepairTemplate = () => {
  if (props.modelValue == null) return;

  deleting.value = true;
  repairResource
    .deleteRepairTemplate(props.modelValue.repairTemplateId)
    .then(() => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(apiKeysResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};

const updateIssues = (newIssues: RepairTemplateIssue[]) => {
  if (props.modelValue) {
    props.modelValue.issues = [...newIssues];
  }
};
</script>
